.enlargeImage2-header-extend{
    background:#505050!important;
    /* text-align: justify !important;
    text-justify: inter-word !important; */
}

.enlargeImage2-h1-extend{
    color:#E0D7C6!important;
    font-size: 43px !important;
    font-weight: bold;
}

.enlargeImage2-h2-extend{
    color:#C3C3C3!important;
    text-align: justify !important;
    text-justify: inter-word !important;
}

.enlargeImage2-navLink-extend{
    color:#E0D7C6!important
}